import React, { useState, useEffect, useRef, useCallback } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact,faHtml5,faCss3Alt,faSquareJs,faSass,faGithub, faFontAwesomeFlag} from '@fortawesome/free-brands-svg-icons'; 

export const Case = React.memo(({ text, imgSrc, subTitle, title, icons, siteLink, gitLink }) => {

    const [imgStyle, setImgStyle] = useState({});
    const imgRef = useRef(null);

    useEffect(() => {
        const updateImgStyle = () => {
            if (imgRef.current) {
                const width = imgRef.current.offsetWidth;
                const height = (width * 9) / 16;
                setImgStyle({
                    height: `${height}px`,
                    backgroundImage: `url(${imgSrc})`
                });
            }
        };

        updateImgStyle();
        window.addEventListener('resize', updateImgStyle);

        return () => {
            window.removeEventListener('resize', updateImgStyle);
        };
    }, [imgSrc]);

    const handleGitLinkClick = useCallback(() => {
        if (gitLink) {
            window.open(gitLink, '_blank');
        }
    }, [gitLink]);

    return (
        <div className="project">
            <div className="project-img" ref={imgRef} style={imgStyle} role="img" aria-label={title}>

                {gitLink && (
                    <div className="smIcons" role="button" tabIndex={0} onClick={handleGitLinkClick} aria-label="GitHub project link">
                        <FontAwesomeIcon className="icon" icon={faGithub} />
                    </div>
                )}

            </div>
            <div className="case-desc">
                <div className="titles">
                    <h3>{title}</h3>
                    <p>{subTitle}</p>
                </div>
                <div className="desc-icons">
                    {icons.map(icon => {
                        const iconMap = {
                            react: faReact,
                            html: faHtml5,
                            css: faCss3Alt,
                            js: faSquareJs,
                            scss: faSass,
                            github: faGithub,
                            fontawesome: faFontAwesomeFlag
                        };

                        return (
                            icon === 'jest' || icon === 'leaflet' ? (
                                <i className={`icon ${icon}`} alt={`${icon} logo`} />
                            ) : (
                                <FontAwesomeIcon className="icon" key={icon} icon={iconMap[icon]} />
                            )
                        );
                    })}
                </div>
                <p>{text}</p>
                <button type="button" onClick={() => window.open(siteLink, '_blank')}>
                    Test
                </button>
            </div>
        </div>
    );
});