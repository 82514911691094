import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';

export const Contact = ({ toggle }) => {

    return (
        <section className="contact-bg">
            <div className="container">
                <button onClick={toggle} className="closeBtn">
                    <FontAwesomeIcon className='closeIcon' icon={faCircleXmark} />
                </button>
                <form
                    className="form"
                    action="https://formsubmit.co/ruildo5@live.ca"
                    method="POST"
                >
                    <div className="form-group">
                        <label htmlFor="name" className="form-label">Your Name</label>
                        <input type="text" className="form-control" id="name" name="name" placeholder="Jhon Doe" tabIndex="1" required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="email" className="form-label">Your Email</label>
                        <input type="email" className="form-control" id="email" name="email" placeholder="jhon@doe.com" tabIndex="2" required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="subject" className="form-label">Subject</label>
                        <input type="text" className="form-control" id="subject" name="subject" placeholder="Hello There!" tabIndex="3" required/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="message" className="form-label">Message</label>
                        <textarea className="form-control" rows="5" cols="50" id="message" name="message" placeholder="Enter Message..." tabIndex="4"/>
                    </div>
                    <div>
                        <button type="submit" className="submit">Send Message!</button>
                    </div>
                </form>
            </div>
        </section>
    );
}