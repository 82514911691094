import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faHtml5, faCss3Alt, faSquareJs, faSass } from '@fortawesome/free-brands-svg-icons';

export const About = React.memo(() => {
    const skills = [faHtml5, faCss3Alt, faSass, faSquareJs, faReact];

    return (
        <section id="about" className="about">
            <h2>About Me</h2>
            <p>
                I’m a Junior Frontend Web Developer, I completed my program and obtained a Diploma in
                Interactive Media Design while working at Sears. I’ve also interned at, and worked for Graphic
                Plus Media, Web Design & Marketing Agency. This has given me a range of practical capabilities.
            </p>
            <div className="skill-icon">
                {skills.map((icon, index) => (
                    <FontAwesomeIcon key={index} icon={icon} alt={`${icon.iconName} logo`} className=
                    {`hero-icons icon ${icon}`}  aria-label={`${icon.iconName} logo`} />
                ))}
                <i className="icon jest" alt="jest logo" aria-label='Jest'/>
                <i className="icon ps" alt="Adobe Photoshop logo" aria-label='Adobe Photoshop'/>
                <i className="icon ae" alt="Adobe After Effect logo" aria-label='Adobe After Effect'/>
                <i className="icon ai" alt="Adobe Illustrator logo" aria-label='Adobe Illustrator'/>
            </div>
            <p>
                I look forward to utilizing my skills in web development and design, for future projects!
            </p>
            <button className="resume" onClick={() => window.location.href = 'https://frontend-web-dev-resume.tiiny.co/'}>
                Check My Resume
            </button>
        </section>
    );
});