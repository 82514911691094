import React, { useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard, faComments } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

export const NavBar = React.memo(({contactToggle}) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = useCallback(() => setIsOpen(prev => !prev), []);

    const scrollToSection = (event, sectionId) => {
        event.preventDefault();
        const section = document.getElementById(sectionId);
        if (section) {
            const sectionPosition = section.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = sectionPosition - 80;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    return (
        <nav>
            <a href="./index.html" className={`${isOpen ? 'open' : ''} logo`} aria-label="Home"> Ruildo </a>
            <ul className={`nested-menue ${isOpen ? 'open' : ''}`} aria-label="Navigation Menu">
                <li>
                    <a onClick={(e) =>{ contactToggle(); toggle()}} aria-label="Let's talk">
                        <FontAwesomeIcon icon={faComments} className='icon' />
                        <span>Lets talk</span>
                    </a>
                </li>
                <li>
                    <a
                        onClick={(e) => {scrollToSection(e, 'about'); toggle();}} aria-label="Get to know me" >
                        <FontAwesomeIcon icon={faIdCard} className='icon' />
                        <span>Get to know me</span>
                    </a>
                </li>
                <li className='socials'>
                    <a href='https://github.com/mcMoffin' aria-label="GitHub Profile">
                        <FontAwesomeIcon icon={faGithub} />
                        <span>@mcMoffin</span>
                    </a>
                    <a href='https://www.linkedin.com/in/ruildo-dcl' aria-label="LinkedIn Profile">
                        <FontAwesomeIcon icon={faLinkedinIn} className='linkedIn' />
                        <span>Ruildo</span>
                    </a>
                </li>
            </ul>
            <button className={`${isOpen ? 'open' : ''} burger`} onClick={toggle} aria-label="Toggle navigation">
                <div className="bLine1"></div>
                <div className="bLine2"></div>
                <div className="bLine3"></div>
            </button>
        </nav>
    );
});