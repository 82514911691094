import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

export const Hero = React.memo(({ contact }) => {
    return (
        <header className="hero">
            <div className='socials' aria-label="Social Media Links">
                <div className='icon'>
                    <a href='https://github.com/mcMoffin' aria-label="GitHub Profile">
                        <FontAwesomeIcon icon={faGithub} />
                    </a>
                </div>
                <div className='icon'>
                    <a href='https://www.linkedin.com/in/ruildo-dcl' aria-label="LinkedIn Profile">
                        <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                </div>
            </div>
            <div className='hero-content'>
                <div className='hero-main-text'>
                    <h1 className='front-title'>Hello, my name is <em>Ruildo.</em></h1>
                    <p className='hero-tagline'>
                        A Junior Front-End Web Developer skilled in creating responsive, user-friendly websites, with a passion for learning and applying the latest web technologies to deliver innovative solutions.
                    </p>
                </div>
                <button className="cta" onClick={contact} aria-label="Let's Talk">
                    <FontAwesomeIcon className='icon' icon={faComments} />
                    <span>Lets Talk</span>
                </button>
            </div>
        </header>
    );
});