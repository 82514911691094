import { useState,useEffect } from 'react';
import { NavBar } from './components/nav/NavBar';
import { Contact } from './components/contatct/Contact';
import { Hero } from './components/hero/Hero'
import { About } from './components/about/About'
import { CaseGrid } from './components/case-grid/Case-grid'

import './style.css';

function App() {
  const caseData = [
    {
        text:`Test HTML by Tiiny Host is a simple tool for ensuring your web pages are error-free and fully compliant with industry standards. Our powerful tool scans your HTML code, identifies any errors or warnings, and provides clear and actionable feedback to help you improve your code's performance.`,
        imgSrc:`${process.env.PUBLIC_URL}/assets/screenShots/test-site.jpg`,
        coverImg: `${process.env.PUBLIC_URL}/assets/screenShots/test-site-cvImg.jpg`,
        title:'test HTLM',
        subTitle: 'Online HTML code test applet',
        icons: ['html','scss','react','jest','fontawesome'],
        siteLink:'https://tiiny.host/test-html/',
    },
    {
        text:`this was a project to test my progress, that I'd done for Tiiny Host to verrify my abilaties. it uses an approved layout created by the in house designer.`,
        imgSrc: `${process.env.PUBLIC_URL}/assets/screenShots/dashBord.jpg`,
        coverImg: `${process.env.PUBLIC_URL}/assets/screenShots/dashBord-cvImg.jpg`,
        title:'Account Dashboard',
        subTitle:'UI Layout',
        icons: ['html','scss','react','fontawesome'],
        siteLink:'https://mcmoffin.github.io/Skill_Check',
        gitLink:'https://github.com/mcMoffin/Skill_Check'
    },
    {
        text:`This is the "IP Address Tracker" project that I'd done for Front End Mentor.`,
        imgSrc: `${process.env.PUBLIC_URL}/assets/screenShots/ip-tracker.jpg`,
        coverImg: `${process.env.PUBLIC_URL}/assets/screenShots/ip-tracker-cvImg.jpg`,
        title:'IP Tracker',
        subTitle:'Web App',
        icons: ['html','scss','react','fontawesome','leaflet'],
        siteLink:'https://mcmoffin.github.io/ip-tracker/',
        gitLink:'https://github.com/mcMoffin/ip-tracker'
    },
    {
        text:'this "Pixel Galaria" is an image Gallery that useses an API to generate the information that is displayed. Using react to update the the UI.',
        imgSrc: `${process.env.PUBLIC_URL}/assets/screenShots/pixaria.jpg`,
        coverImg: `${process.env.PUBLIC_URL}/assets/screenShots/pixaria-cvImg.jpg`,
        title:'Pixaria',
        subTitle:'React Image Gallery',
        icons: ['html', 'js','scss','react'],
        siteLink:'https://mcmoffin.github.io/pixaria/',
        gitLink:'https://github.com/mcMoffin/pixaria'
    },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [updatedCaseData, setUpdatedCaseData] = useState(caseData);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleToggle = () => {
        document.body.classList.toggle('no-scroll');
        toggle();
    };

  useEffect(() => {
    const handleResize = () => {
      const suffix = window.innerWidth < 720 ? '' : '-web'; // Adjust condition as needed
      const newCaseData = caseData.map(item => ({
        ...item,
        imgSrc: item.imgSrc.replace(/(\.jpg|\.JPG|\.png)$/, `${suffix}$1`),
        coverImg: item.coverImg.replace(/(\.jpg|\.JPG|\.png)$/, `${suffix}$1`),
      }));
      setUpdatedCaseData(newCaseData);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="App">
      <NavBar contactToggle={handleToggle} />
      <Hero contact={toggle} />
      {isOpen ? <Contact toggle={handleToggle} /> : null}
      <div className="main_content_wrapper">
        <About />
        <CaseGrid caseData={updatedCaseData} />
      </div>
    </div>
  );
}

export default App;
