import React from 'react';
import { Case } from '../case/Case';

export const CaseGrid = React.memo(({ caseData }) => {
    return (
        <section className='project-list' aria-labelledby="projects">
            <h2 id="projects">Projects</h2>
            {caseData.map(data => (
                <Case
                    key={data.title}
                    {...data}
                />
            ))}
        </section>
    );
});